import { useState, useRef, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import { blueGrey } from '@mui/material/colors';
// assets
import { IconLogout, IconSettings } from '@tabler/icons';
import { MENU_OPEN } from 'store/actions';
import Button from '@mui/material/Button';
import Discord from 'assets/images/discord.png';

// ==============================|| PROFILE MENU ||============================== //

import AuthContext from '../../../../context/auth/AuthContext';

function stringAvatar(name) {
    var latter1 = name.split(' ')[0][0];
    var latter2 = name.split(' ')[1][0];
    return {
        sx: {
            bgcolor: blueGrey[500],
            color: '#fff !important'
        },
        children: `${latter1 ? latter1 : ''}${latter2 ? latter2 : ''}`
    };
}

const ProfileSection = () => {
    const context = useContext(AuthContext);
    const { subscription, userDetails, logout } = context;

    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        await logout();
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        dispatch({ type: MENU_OPEN, id: '' });
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    let btn = subscription?.result == null ? 'subscription' : 'account?subscribe=4';

    return (
        <>
            {Object.keys(userDetails).length == 0 ? (
                ''
            ) : userDetails.result.pro_status == 0 ? (
                subscription?.result == null ? (
                    <>
                        <Button
                            onClick={(event) => handleListItemClick(event, 1, btn)}
                            className="Btn"
                            sx={{
                                boxShadow: 'none',
                                mr: 3,
                                padding: '11.8px 35px',
                                color: '#fff',
                                display: { xs: 'none', md: 'flex' }
                            }}
                        >
                            <svg viewBox="0 0 576 512" height="1em" class="logoIcon">
                                <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path>
                            </svg>
                            UPGRADE TO PRO
                        </Button>

                        {/* <button class="Btn">
                            <svg viewBox="0 0 576 512" height="1em" class="logoIcon">
                                <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path>
                            </svg>
                            UPGRADE TO PRO
                        </button> */}

                        <Button
                            onClick={(event) => handleListItemClick(event, 1, btn)}
                            className="Btn"
                            sx={{
                                boxShadow: 'none',
                                color: '#fff',
                                display: { md: 'none' }
                            }}
                        >
                            <svg viewBox="0 0 576 512" height="1em" class="logoIcon">
                                <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path>
                            </svg>
                            UPGRADE TO PRO
                        </Button>
                    </>
                ) : (
                    ''
                )
            ) : (
                ''
            )}

            <Button
                variant="outlined"
                target="_blank"
                sx={{ color: '#fff', mr: 1, display: { xs: 'none', md: 'flex' } }}
                size="small"
                href="https://discord.com/invite/Bh4vNuaayz"
            >
                Weekly Group Coaching on Discord! Join Here <Avatar sx={{ ml: 1 }} alt="Discord" src={Discord} />
            </Button>

            <Button
                target="_blank"
                sx={{ color: '#fff', display: { lg: 'none' } }}
                size="small"
                href="https://discord.com/invite/Bh4vNuaayz"
            >
                <Avatar sx={{ ml: 1 }} alt="Discord" src={Discord} />
            </Button>

            <Chip
                className="headerAvatar"
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    typeof userDetails?.result?.profileImage != '' && userDetails?.result?.firstName != '' ? (
                        <Avatar
                            {...stringAvatar(userDetails?.result?.firstName + ' ' + userDetails?.result?.lastName)}
                            src={
                                userDetails?.result?.profileImage
                                    ? process.env.REACT_APP_WEB_ROOT_IMAGE_PATH + '/uploads/' + userDetails?.result?.profileImage
                                    : ''
                            }
                        />
                    ) : (
                        <Avatar />
                    )
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                    sx={{ background: 'rgb(19, 23, 35)' }}
                                >
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4" sx={{ color: '#fff' }}>
                                                    Welcome Back,
                                                </Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400, color: '#fff' }}>
                                                    {userDetails?.result?.firstName}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Divider sx={{ borderColor: '#3e4042' }} />

                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                        <Box sx={{ p: 2 }}>
                                            <UpgradePlanCard handleClose={handleClose} />
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    background: '#17181B',
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    onClick={(event) => handleListItemClick(event, 0, '/account')}
                                                >
                                                    <ListItemIcon sx={{ color: '#fff' }}>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                </ListItemButton>
                                                {/* <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 1}
                                                    onClick={(event) => handleListItemClick(event, 1, '/user')}
                                                >
                                                    <ListItemIcon>
                                                        <IconUser stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Grid container spacing={1} justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="body2">Profile</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                                </ListItemButton> */}
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon sx={{ color: '#fff' }}>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
