// assets
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HeadsetIcon from '@mui/icons-material/Headset';
// constant
const icons = {
    HomeOutlinedIcon,
    SchoolOutlinedIcon,
    VideoLibraryOutlinedIcon,
    AccountBalanceWalletOutlinedIcon,
    ConfirmationNumberOutlinedIcon,
    HeadsetIcon,
    GroupOutlinedIcon,
    FactCheckIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.HomeOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'courses',
            title: 'Courses',
            type: 'item',
            url: '/courses',
            icon: icons.SchoolOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'videos',
            title: 'Video Guides',
            type: 'item',
            url: '/videos',
            icon: icons.VideoLibraryOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'coaching',
            title: 'Coaching',
            type: 'item',
            url: '/coaching',
            icon: icons.HeadsetIcon,
            breadcrumbs: false
        },
        // {
        //     id: 'booking-list',
        //     title: 'Booking List',
        //     type: 'item',
        //     url: '/booking-list',
        //     icon: icons.FactCheckIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'subscription',
        //     title: 'Subscription',
        //     type: 'item',
        //     url: '/subscription',
        //     icon: icons.InventoryOutlinedIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'referral',
            title: 'Referral',
            type: 'item',
            url: '/referral',
            icon: icons.GroupOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default pages;
