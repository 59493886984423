import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AuthContext from './AuthContext';
import { GetData, PostData, PutData, PutFormData } from '../../services';
import { useLocalStorage } from './useLocalStorage';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const AuthState = (props) => {
    const [user, setUser] = useLocalStorage('clymb', null);
    const [userDetails, setUserDetails] = useState([]);
    const [subscription, setSubscription] = useState([]);
    // const tokenTime = user ? user.token.accessTokenExpiresIn : '';
    // const [tokenExpirytime, setTokenExpirytime] = useState(tokenTime);

    const navigate = useNavigate();

    const addUser = async (data) => {
        const res = await PostData(false, '/users', data);
        if (res.status === 200) {
            setUser(res.payload.result);
            return res.payload;
        }
    };

    const updateUser = async (data, id) => {
        const res = await PutFormData(true, '/users/' + id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updatePassword = async (data) => {
        const res = await PutData(true, '/users/change-password/' + data.id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const confirmUser = async ({ id, status }) => {
        const res = await PutData(false, '/users/updateStatus/' + id, { status });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const loginUser = async (data) => {
        const res = await PostData(false, '/users/login', data);
        if (res.status === 200) {
            setUser(res.payload.result);
            return res.payload;
        }
    };

    const logout = async () => {
        localStorage.removeItem('clymb');
        navigate('/', { replace: true });
        setUser(null);
    };

    const getUserDetails = async ({ id }) => {
        const res = await GetData(true, '/users/' + id);
        setUserDetails(res.payload);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getSubscription = async ({ id }) => {
        const res = await GetData(true, '/users/subscription/' + id);
        if (res.status === 200) {
            setSubscription(res.payload);
            return res.payload;
        }
    };

    const refreshToken = async (data) => {
        const res = await PostData(true, '/users/refresh-token', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const forgotPassword = async (data) => {
        const res = await PostData(false, '/users/forgot-password', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const verifyResetPasswordLink = async (id, token) => {
        const res = await GetData(false, '/users/reset-password/' + id + '/' + token);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const resetPassword = async (id, token, data) => {
        const res = await PostData(false, '/users/reset-password/' + id + '/' + token, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getDashboardDetails = async (id) => {
        const res = await PostData(false, '/users/get-dashboard-details', { id });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const createSubscription = async (data) => {
        const res = await PostData(true, '/paypal', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const createBookingPaypalOrder = async (data) => {
        const res = await PostData(true, '/paypal/create-booking-payment', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const subscriptionApproved = async (data) => {
        const res = await PostData(true, '/paypal/approved', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const bookingApprovedApproved = async (data) => {
        const res = await PostData(true, '/paypal/booking-approved', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const subscriptionCancel = async (data) => {
        const res = await PostData(true, '/paypal/cancel', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const resubscribeSubscription = async (data) => {
        const res = await PostData(true, '/paypal/re-subscribe', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const stripeCreateSubscription = async (data) => {
        const res = await PostData(true, '/stripe', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const stripeCreateCoachPayment = async (data) => {
        const res = await PostData(true, '/stripe/create-stripe-booking-payment', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const stripeSubscriptionCancel = async (data) => {
        const res = await PostData(true, '/stripe/cancel', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const stripeSubscriptionResubscripe = async (data) => {
        const res = await PostData(true, '/stripe/re-subscribe', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const insertDataSubscription = async (data) => {
        const res = await PostData(true, '/stripe/add-data', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const insertStripeBookingData = async (data) => {
        const res = await PostData(true, '/stripe/add-booking-data', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const referralDetails = async ({ id, ref }) => {
        const res = await GetData(true, '/users/referral-details/' + id + '/' + ref);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const seenVideoCount = async (data) => {
        const res = await PostData(true, '/videos/seen-videos-count', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    useEffect(() => {
        (async () => {
            if (user) {
                const token = user ? user.token.access_token : '';
                const { id } = jwt_decode(token);
                await getUserDetails({ id });
            }
        })();
    }, [user]);

    useEffect(() => {
        (async () => {
            if (user) {
                const token = user ? user.token.access_token : '';
                const { id } = jwt_decode(token);
                await getSubscription({ id });
            }
        })();
    }, [user]);

    const updatePopup = async (data) => {
        const res = await PostData(true, '/users/update-popup', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getList = async (data) => {
        const res = await PostData(true, '/coach/list', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const coachDetails = async (data) => {
        const res = await PostData(true, '/coach/coach-profile', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const bookingDetails = async (data) => {
        const res = await PostData(true, '/users/booking-details', data);
        if (res.status === 200) {
            return res.payload;
        }
    };
    const bookingList = async (data) => {
        const res = await PostData(true, '/users/booking-list', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const Roles = async () => {
        const res = await GetData(true, '/courses/roles');
        if (res.status === 200) {
            return res.payload;
        }
    };

    const couponPlan = async (data) => {
        const res = await PostData(true, '/users/subscription-plans', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                userDetails,
                user,
                addUser,
                confirmUser,
                loginUser,
                logout,
                refreshToken,
                updateUser,
                getUserDetails,
                updatePassword,
                forgotPassword,
                verifyResetPasswordLink,
                resetPassword,
                getDashboardDetails,
                createSubscription,
                subscriptionApproved,
                subscription,
                subscriptionCancel,
                getSubscription,
                stripeCreateSubscription,
                stripeSubscriptionCancel,
                insertDataSubscription,
                referralDetails,
                stripeSubscriptionResubscripe,
                resubscribeSubscription,
                seenVideoCount,
                updatePopup,
                getList,
                coachDetails,
                createBookingPaypalOrder,
                bookingApprovedApproved,
                stripeCreateCoachPayment,
                insertStripeBookingData,
                bookingDetails,
                bookingList,
                Roles,
                couponPlan
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

AuthState.propTypes = {
    children: PropTypes.node
};

export default AuthState;
