import { useState, useRef, useEffect, useContext } from 'react';
// material-ui
import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
// styles
const CardStyle = styled(Card)(({ theme }) => ({
    background: '#1d89e4',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '19px solid ',
        borderColor: '#01a5fc',
        borderRadius: '50%',
        top: '65px',
        right: '-150px'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '3px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '145px',
        right: '-70px'
    },
    '& small': {
        color: '#000'
    }
}));
import { MENU_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../../context/auth/AuthContext';
import moment from 'moment-timezone';

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const UpgradePlanCard = ({ handleClose }) => {
    const anchorRef = useRef(null);
    const theme = useTheme();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const context = useContext(AuthContext);
    const { subscription, userDetails } = context;

    const handleListItemClick = (event, index, route = '') => {
        dispatch({ type: MENU_OPEN, id: '' });
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    let days = '';
    let text = '';
    // if (subscription?.result != null) {
    //     var expiration = moment(subscription?.result?.end_date).format('YYYY-MM-DD');
    //     var current_date = moment().format('YYYY-MM-DD');
    //     days = moment(expiration).diff(current_date, 'days');

    //     if (days == '0') {
    //         text = 'Renew Today';
    //     }
    //     if (days == 1) {
    //         text = days + ' Day left';
    //     }
    //     if (days > 1) {
    //         text = days + ' Days left';
    //     }
    // }

    let btn = subscription?.result == null ? 'subscription' : 'account?subscribe=4';

    return (
        <>
            {Object.keys(userDetails).length == 0 ? (
                ''
            ) : (
                <CardStyle>
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="h4" sx={{ color: '#fff' }}>
                                    Subscription
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h4" sx={{ color: '#fff' }}></Typography>
                            </Grid>
                            <Grid item>
                                <Stack direction="row">
                                    <AnimateButton>
                                        <Button
                                            variant="contained"
                                            sx={{ boxShadow: 'none', background: 'rgb(19, 23, 35)' }}
                                            onClick={(event) => handleListItemClick(event, 1, btn)}
                                        >
                                            {userDetails.result.pro_status == 0
                                                ? subscription?.result == null
                                                    ? 'Become a Member'
                                                    : 'PRO Member'
                                                : 'PRO Member'}
                                        </Button>
                                    </AnimateButton>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardStyle>
            )}
        </>
    );
};

export default UpgradePlanCard;
