import { lazy } from 'react';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
const stripePromise2 = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY2);

// pages routing
const Dashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const Courses = Loadable(lazy(() => import('views/pages/courses')));
const CoursesDetails = Loadable(lazy(() => import('views/pages/courses/details')));
const Videos = Loadable(lazy(() => import('views/pages/videos')));
const Profile = Loadable(lazy(() => import('views/pages/profile')));
const Subscription = Loadable(lazy(() => import('views/pages/subscription')));
const Referral = Loadable(lazy(() => import('views/pages/referral')));
const Success = Loadable(lazy(() => import('views/pages/success')));
const BookingSuccess = Loadable(lazy(() => import('views/pages/success/booking-success')));
const Coaches = Loadable(lazy(() => import('views/pages/coaches')));
const CoachDetails = Loadable(lazy(() => import('views/pages/coaches/profile')));
const ErrorPage = Loadable(lazy(() => import('views/pages/error-404')));
const BookingList = Loadable(lazy(() => import('views/pages/booking-list')));

import CoursesState from '../context/courses/CoursesState';
import VideosState from '../context/videos/VideosState';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    element: <MainLayout />,
    children: [
        {
            path: '*',
            element: <ErrorPage />
        },
        {
            path: '/dashboard',
            element: (
                <CoursesState>
                    <VideosState>
                        <Dashboard />
                    </VideosState>
                </CoursesState>
            )
        },
        {
            path: '/success',
            element: <Success />
        },
        {
            path: 'courses',
            element: (
                <CoursesState>
                    <Courses />
                </CoursesState>
            )
        },
        {
            path: '/:course/:video',
            element: (
                <CoursesState>
                    <CoursesDetails />
                </CoursesState>
            )
        },
        {
            path: 'videos',
            element: (
                <VideosState>
                    <Videos />
                </VideosState>
            )
        },
        {
            path: 'coaching',
            element: (
                <VideosState>
                    <Coaches />
                </VideosState>
            )
        },
        {
            path: 'coach/:username',
            element: (
                <Elements stripe={stripePromise2}>
                    <CoachDetails />
                </Elements>
            )
        },
        {
            path: 'booking-success/:id',
            element: (
                <VideosState>
                    <BookingSuccess />
                </VideosState>
            )
        },
        {
            path: 'subscription',
            element: (
                <Elements stripe={stripePromise}>
                    <Subscription />
                </Elements>
            )
        },
        {
            path: 'referral',
            element: <Referral />
        },
        {
            path: 'account',
            element: <Profile />
        },
        {
            path: 'booking-list',
            element: <BookingList />
        }
    ]
};

export default MainRoutes;
