import { useState, useEffect, useContext } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';
import jwt_decode from 'jwt-decode';
// project imports
import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';
import CartSection from './CartSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { Link } from 'react-router-dom';

// project imports
import logo from 'assets/images/Rankup WHITE.png';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import AuthContext from '../../../context/auth/AuthContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    let token = window.localStorage.getItem('clymb');
    const { id } = jwt_decode(token);
    const context = useContext(AuthContext);
    const { userDetails, subscription, seenVideoCount, updatePopup } = context;

    const [videoCount, setVideoCount] = useState(0);

    const [popUp, setPopUp] = useState(0);

    useEffect(() => {
        (async () => {
            const productsList = await seenVideoCount({ userId: id });
            setVideoCount(productsList.count);
        })();
    }, [seenVideoCount]);

    useEffect(() => {
        setPopUp(userDetails?.result?.popup);
    }, [userDetails?.result?.popup, setPopUp]);

    let pageTitle = document.location.pathname;
    let Title = '';
    switch (pageTitle) {
        case '/dashboard':
            Title = `Rankup.net | Dashboard`;
            break;
        case '/courses':
            Title = `Rankup.net | Courses`;
            break;
        case '/videos':
            Title = `Rankup.net | Videos`;
            break;
        case '/coaching':
            Title = `Rankup.net | Coaching`;
            break;
        case '/referral':
            Title = `Rankup.net | Referral`;
            break;
        case '/account':
            Title = `Rankup.net | Profile`;
            break;
        default:
            Title = `Rankup.net`;
            break;
    }

    useEffect(() => {
        document.title = Title;
    }, [Title]);

    const [displayPopUp, setDisplayPopUp] = useState(true);

    // when pop-up is closed this function triggers
    const handleClose = async () => {
        let token = window.localStorage.getItem('clymb');
        const { id } = jwt_decode(token);
        await updatePopup({ userId: id });
        setPopUp(0);
        setDisplayPopUp(false);
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }, flexGrow: 1 }}>
                    <ButtonBase disableRipple component={Link} to="/dashboard">
                        <img src={logo} alt="Rankup" style={{ borderRadius: '5px', maxWidth: '30px', marginLeft: '15px' }} width="170px" />
                    </ButtonBase>
                </Box>
                <ButtonBase
                    sx={{
                        borderRadius: '12px',
                        overflow: 'hidden',
                        display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' }
                    }}
                >
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <CartSection />
            <ProfileSection />

            {subscription?.result == null && userDetails?.result?.pro_status == 0 && videoCount >= 3 && popUp == 1
                ? displayPopUp && (
                      <BootstrapDialog fullWidth={true} maxWidth="md" open={displayPopUp} onClose={() => handleClose()}>
                          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                              <Typography style={{ fontSize: '1.2rem' }} align="center" gutterBottom color="#000">
                                  Ready to start climbing?
                              </Typography>
                          </BootstrapDialogTitle>
                          <DialogContent dividers>
                              <Typography variant="subtitle1" gutterBottom>
                                  Hey there! We're glad that you like our Free Content... But if you really want to take your rank to the
                                  next level, consider our Pro Membership!
                              </Typography>
                              <Typography variant="subtitle1" gutterBottom>
                                  As a Rankup.net Pro Member you unlock all our our Challenger Courses, weekly Group Coaching Calls, a
                                  private Q&A Channel where you can ask questions 24/7, and much more!
                              </Typography>

                              <Typography variant="subtitle1" sx={{ mt: 3 }} align="center" gutterBottom>
                                  <Button component={Link} onClick={handleClose} to="/subscription" variant="contained">
                                      Yes! Show Me More!
                                  </Button>
                              </Typography>
                          </DialogContent>
                      </BootstrapDialog>
                  )
                : ''}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
