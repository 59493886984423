import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ForgotPassword')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ResetPassword')));
const AuthConfirmAccount = Loadable(lazy(() => import('views/pages/authentication/authentication/ConfirmAccount')));
const Referral = Loadable(lazy(() => import('views/pages/authentication/authentication/Referral')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin3 />
        },
        {
            path: '/register',
            element: <AuthRegister3 />
        },
        {
            path: '/forgot-password',
            element: <AuthForgotPassword />
        },
        {
            path: '/reset-password/:id/:token',
            element: <AuthResetPassword />
        },
        {
            path: '/confirm-account/:id',
            element: <AuthConfirmAccount />
        },
        {
            path: '/referral/:id',
            element: <Referral />
        }
    ]
};

export default AuthenticationRoutes;
