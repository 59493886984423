import PropTypes from 'prop-types';
import CoursesContext from './CoursesContext';
import { PostData, GetData } from '../../services';

const CoursesState = (props) => {
    const getList = async (data) => {
        const res = await PostData(true, '/courses/list', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getListChampions = async (data) => {
        const res = await PostData(true, '/courses/champions', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getRoleCourses = async (data) => {
        const res = await PostData(true, '/courses/role-courses', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getComments = async (data) => {
        const res = await PostData(true, '/videos/show-comments', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addComments = async (data) => {
        const res = await PostData(true, '/videos/add-comments', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const deleteComments = async (data) => {
        const res = await PostData(true, '/videos/delete-comments', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getDetails = async (data) => {
        const res = await PostData(true, '/courses/course-details', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getSeenCourses = async (data) => {
        const res = await PostData(true, '/courses/seen-courses', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addSeenCourses = async (data) => {
        const res = await PostData(true, '/courses/add-seen-view', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addSeenVideos = async (data) => {
        const res = await PostData(true, '/videos/add-seen-videos', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const Roles = async () => {
        const res = await GetData(true, '/courses/roles');
        if (res.status === 200) {
            return res.payload;
        }
    };

    const Topics = async () => {
        const res = await GetData(true, '/courses/topics');
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <CoursesContext.Provider
            value={{
                getList,
                getDetails,
                addSeenCourses,
                getSeenCourses,
                Roles,
                Topics,
                getListChampions,
                getComments,
                addComments,
                deleteComments,
                getRoleCourses,
                addSeenVideos
            }}
        >
            {props.children}
        </CoursesContext.Provider>
    );
};

CoursesState.propTypes = {
    children: PropTypes.node
};

export default CoursesState;
