import PropTypes from 'prop-types';
import VideosContext from './VideosContext';
import { PostData } from '../../services';

const CoursesState = (props) => {
    const getList = async (data) => {
        const res = await PostData(true, '/videos/list', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const sliderList = async () => {
        const res = await PostData(true, '/videos/slider-video-list');
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <VideosContext.Provider
            value={{
                getList,
                sliderList
            }}
        >
            {props.children}
        </VideosContext.Provider>
    );
};

CoursesState.propTypes = {
    children: PropTypes.node
};

export default CoursesState;
